import React from "react"
import { useTranslation } from "react-i18next"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import "../components/i18n"

const PrivacyPolicy: React.FC = () => {
  const { t, i18n } = useTranslation()
  return (
    <Layout title={t("title")}>
      <SEO title={t("privPolicy.title")} />
      <h3>
        {t("privPolicy.h1.title")}
      </h3>
      <p>
        {t("privPolicy.h1.p1")}
      </p>
      <p>
        {t("privPolicy.h1.p2")}
        <a href="mailto:cogitosolitaire@gmail.com">cogitosolitaire@gmail.com</a>
      </p>
    </Layout>
  )
}

export default PrivacyPolicy
